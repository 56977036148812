import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'

import Icon from '@/components/ui/Icon'
import { LanguageToggles } from '@/layouts/NavBar'
import WifButton from 'src/components/ui/WifButton'
import { getInitials } from 'src/lib/stringUtils'
import { AuthContext } from 'src/providers/AuthProvider'

const ListToggle: React.FC<PropsWithChildren<{ id: string }>> = ({ children, id }) => {
  return (
    <div
      data-testid={`account-list-toggle-${id}`}
      className='flex justify-between gap-10 xs:gap-20 font-medium'
    >
      {children}
    </div>
  )
}

const ProfileToggles: React.FC<{}> = ({}) => {
  const { t } = useTranslation()
  const { authLogout } = useContext(AuthContext)
  const router = useRouter()

  async function logout() {
    await authLogout()
    router.push('/login')
  }

  return (
    <div className='px-4 py-5 flex flex-col gap-5'>
      <Link href={process.env.NEXT_PUBLIC_WIFKAIN_DOMAIN as string}>
        <ListToggle id='wifkain-homepage'>
          <div>{t('common:Wifkain_Homepage')}</div>
          <Image src='/icons/ic-simple-home.svg' alt='Wifkain Homepage' width={24} height={24} />
        </ListToggle>
      </Link>
      <div onClick={() => logout()}>
        <ListToggle id='wifkain-logout'>
          <div>{t('common:Word_Logout')}</div>
          <Image src='/icons/ic-logout.svg' alt={t('common:Word_Logout')} width={24} height={24} />
        </ListToggle>
      </div>
    </div>
  )
}

const RightMenu: React.FC<{
  simplified: boolean
  onCreateProduction: () => void
}> = ({ simplified, onCreateProduction }) => {
  const { t, i18n } = useTranslation()
  const auth = useContext(AuthContext)
  const fullName = auth.userData ? `${auth.userData?.firstName} ${auth.userData?.lastName}` : ''
  const [isAccountOpen, setIsAccountOpen] = useState<boolean>(false)
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)
  const accountDropdown = useRef<HTMLDivElement>(null)
  const languageDropdown = useRef<HTMLDivElement>(null)
  const currentLanguage = i18n.language.toUpperCase()

  useEffect(() => {
    function handleClick(event: any) {
      if (
        isAccountOpen &&
        accountDropdown.current &&
        !accountDropdown.current.contains(event.target)
      ) {
        setIsAccountOpen(false)
      }

      if (
        isLanguageOpen &&
        languageDropdown.current &&
        !languageDropdown.current.contains(event.target)
      ) {
        setIsLanguageOpen(false)
      }
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [isAccountOpen, isLanguageOpen])

  return (
    <div className='flex flex-row justify-center items-center gap-8'>
      <div className='max-w-[200px] hidden sm:block'>
        <WifButton
          id='new-production-web'
          text={t('common:Navbar.Start_New_Production')}
          iconSrc='/icons/plus.svg'
          iconHeight={24}
          handleClick={onCreateProduction}
        />
      </div>

      <div
        ref={languageDropdown}
        className='flex flex-row gap-2 justify-center items-center font-bold text-sm cursor-pointer hover:bg-[#F4F4F4] rounded-xl p-[5px]'
        onClick={() => setIsLanguageOpen((prev) => !prev)}
      >
        <Icon iconSrc={'/icons/ic-globe.svg'} width={24} height={24} alt='chevron' />
        <div>{currentLanguage}</div>
        {isLanguageOpen && (
          <div
            className={clsx(
              'z-30 absolute top-[60px] xs:top-[80px] bg-white right-[120px] rounded-lg shadow-[0px_4px_14px_0px_rgba(0,0,0,0.15)]'
            )}
          >
            <LanguageToggles />
          </div>
        )}
      </div>

      <div
        ref={accountDropdown}
        className={clsx(
          'xs:flex flex-row gap-2 justify-center items-center',
          simplified ? 'hidden' : 'flex',
          'font-bold text-sm cursor-pointer hover:bg-[#F4F4F4] rounded-xl p-[5px] shrink-0'
        )}
        onClick={() => setIsAccountOpen(!isAccountOpen)}
      >
        <div className='flex w-[40px] h-[40px] xs:w-[48px] xs:h-[48px] overflow-hidden rounded-full bg-[#FFBC99] relative leading-none justify-center items-center '>
          {getInitials(fullName)}
        </div>
        <div className='hidden xs:block'>{auth.userData?.firstName}</div>
        <div className={clsx(isAccountOpen && 'rotate-180')}>
          <Icon iconSrc={'/icons/chevron-down_16x16.svg'} width={16} height={16} alt='chevron' />
        </div>
        {isAccountOpen && (
          <div
            className={clsx(
              'z-30 absolute top-[60px] xs:top-[80px] bg-white right-4 rounded-lg shadow-[0px_4px_14px_0px_rgba(0,0,0,0.15)]'
            )}
          >
            <ProfileToggles />
          </div>
        )}
      </div>
    </div>
  )
}

export default RightMenu
