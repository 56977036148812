import clsx from 'clsx'

const HamburgerIcon: React.FC<{ expanded?: boolean; onClick: () => void }> = ({
  expanded = false,
  onClick,
}) => {
  return (
    <div
      className='w-[20px] h-[15px] relative rotate-0 transition-transform duration-250 ease-in-out cursor-pointer'
      onClick={onClick}
    >
      <span
        className={clsx(
          'top-0 h-[2px] w-full absolute bg-[#333] rounded-[8px] opacity-100 transition-transform duration-250 ease-in-out origin-[left_center]',
          expanded ? 'rotate-45 top-[0px] left-0' : 'rotate-0 left-0 top-0'
        )}
      ></span>
      <span
        className={clsx(
          'top-[7px] h-[2px] w-full absolute bg-[#333] rounded-[8px] opacity-100 left-0 rotate-0 transition-transform duration-250 ease-in-out origin-[left_center]',
          expanded ? 'w-0 opacity-0' : ''
        )}
      ></span>
      <span
        className={clsx(
          'top-[14px] h-[2px] w-full absolute bg-[#333] rounded-[8px] opacity-100 left-0 rotate-0 transition-transform duration-250 ease-in-out origin-[left_center]',
          expanded ? '-rotate-45 top-[18px] left-0' : ''
        )}
      ></span>
    </div>
  )
}

export default HamburgerIcon
