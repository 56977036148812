import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import WifButton from './WifButton'
import { useTranslation } from 'next-i18next'

interface WifModalProps {
  bodyText?: string
  actions?: boolean
  closeButton?: boolean
  mainActionText?: string
  secondaryActionText?: string
  onClose?: () => void
  onMainAction?: () => void
  id: string
  title?: string
  wrapperClass?: string
}

const WifModal: React.FC<PropsWithChildren<WifModalProps>> = ({
  actions = true,
  mainActionText,
  bodyText = true,
  secondaryActionText,
  onClose,
  onMainAction,
  id,
  title,
  children,
  wrapperClass,
}) => {
  const {t} = useTranslation()
  return (
    <div className='h-screen fixed w-full top-0 z-50 left-0 right-0 bg-grey/80 flex items-center'>
      {children ? (
        <div
          data-testid={`wif-modal-${id}`}
          className={clsx(
            'bg-white shadow-[0px_0px_34px_0px_rgba(0,0,0,0.15)] w-full max-w-[800px] mx-auto rounded-[32px] min-h-[120px] max-h-[80vh]',
            wrapperClass
          )}
        >
          {children}
        </div>
      ) : (
        <div
          data-testid={`wif-modal-${id}`}
          className={clsx(
            'bg-white p-8 pt-12 shadow-[0px_0px_34px_0px_rgba(0,0,0,0.15)] relative w-full max-w-[380px] mx-auto rounded-[32px] overflow-hidden',
            wrapperClass
          )}
        >
          {title && <span className='block text-center font-bold text-sm mb-1'>{title}</span>}
          {bodyText && <div className='text-center font-normal text-sm mb-8'>{bodyText}</div>}
          {actions && (
            <div className='flex gap-4'>
              {onClose && (
                <WifButton
                  border={false}
                  type='secondary'
                  id='modal-cancel-action'
                  text={secondaryActionText || t('Word_Cancel')}
                  handleClick={onClose}
                />
              )}
              {onMainAction && (
                <WifButton
                  type='primary'
                  id='modal-approve-action'
                  text={mainActionText || 'OK'}
                  handleClick={onMainAction}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default WifModal
