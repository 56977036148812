import clsx from 'clsx'
import { useRouter } from 'next/router'
import { PropsWithChildren } from 'react'
import NavBar from './NavBar'

const MainContent: React.FC<
  PropsWithChildren<{
    onHamburgerClick: () => void
    simplified: boolean
    title?: string
    onBackAction: () => void
  }>
> = ({ children, simplified, onHamburgerClick, title = '', onBackAction }) => {
  const router = useRouter()
  const homepage = router.pathname === '/'
  return (
    <div
      className={clsx(
        'grid grid-cols-[1fr] max-h-screen grid-rows-[60px_1fr]',
        'xs:grid-rows-[90px_1fr]'
      )}
    >
      <NavBar
        simplified={simplified}
        onHamburgerClick={onHamburgerClick}
        title={title}
        onBackAction={onBackAction}
      />

      <div
        className={clsx(
          'h-full overflow-y-auto min-h-[calc(100vh-60px-32px)]',
          'xs:min-h-[calc(100vh-90px-64px)] xs:pb-0',
          homepage && 'pb-[90px]'
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default MainContent
