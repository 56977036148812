import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import WifDashboardLogo from 'src/components/ui/Icon/WifDashboardLogo'
import { Menu } from 'src/types/ui'

const listMenu: Menu[] = [
  {
    title: 'Dashboard',
    url: '/',
    icon: '/icons/home.svg',
    activeIcon: '/icons/home_active.svg',
  },
  {
    title: 'common:Sidebar.Order_List',
    url: '/orders/list',
    icon: '/icons/ic-transaction.svg',
    activeIcon: '/icons/ic-transaction-active.svg',
  },
  {
    title: 'common:Sidebar.Item_List',
    url: '/productions/list',
    icon: '/icons/ic-production.svg',
    activeIcon: '/icons/ic-production-active.svg',
  },
  {
    title: 'common:Sidebar.Target_Date',
    url: '/orders/timeline',
    icon: '/icons/ic-date.svg',
    activeIcon: '/icons/ic-date-active.svg',
  },
]

const SideBar: React.FC<{
  expanded: boolean
  onOverlayClick: () => void
  onMenuClick: (url: string) => void
}> = ({ expanded, onOverlayClick, onMenuClick }) => {
  return (
    <>
      <div
        className={clsx(
          'fixed z-50 transition-all w-[240px] h-screen py-[30px] bg-[#FAFAFF]',
          'xs:relative xs:overflow-hidden xs:z-0 xs:w-auto ',
          expanded ? 'left-0' : '-left-full xs:left-0'
        )}
      >
        <WifDashboardLogo simplified={!expanded ? true : false} />

        <ul className='mt-8 flex flex-col'>
          {listMenu.map((data, i) => {
            return (
              <SideBarMenu
                key={i}
                menu={data}
                onMenuClick={() => onMenuClick(data.url)}
                expanded={expanded}
              />
            )
          })}
        </ul>
      </div>

      <div
        className={clsx(
          'xs:hidden fixed bg-[#000]/50 z-[49] transition-opacity duration-250 ease-in-out',
          expanded ? 'opacity-100 h-screen w-screen' : 'opacity-0 h-0 w-0'
        )}
        onClick={onOverlayClick}
      ></div>
    </>
  )
}

const SideBarMenu: React.FC<{
  menu: Menu
  onMenuClick: () => void
  expanded: boolean
}> = ({ menu, onMenuClick, expanded }) => {
  const router = useRouter()
  const { title, url, icon, activeIcon } = menu
  const isActive = router.pathname === url
  const { t } = useTranslation()

  return (
    <li
      className={clsx(
        'cursor-pointer items-center px-5 py-3 relative hover:bg-[#F4F4F4]',
        isActive ? 'bg-[#FBB24108] text-[#FBB241]' : '',
        expanded && 'flex gap-3'
      )}
      onClick={onMenuClick}
    >
      <Image src={isActive ? activeIcon : icon} alt={title} height={24} width={24} />
      <span className={clsx(!expanded && 'hidden')}>{t(title)}</span>
    </li>
  )
}

export default SideBar
