export const INQUIRIES_STATUS = {
  INQUIRY: 'Inquiry',
  INPROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  DELIVERY: 'Delivery',
  HOLD: 'Hold',
  REVISION: 'Revision',
}

export const ITEMS_STATUS = {
  DRAFT: 'Draft',
  INQUIRY: 'Enquiry',
  RFQ: 'RFQ',
  QUOTATION_CONFIRMATION: 'Quotation Confirmation',
  SAMPLING_1: 'Sampling 1',
  PPS: 'PPS',
  SAMPLING_REVIEW: 'Sampling Review',
  PPS_REVIEW: 'PPS Review',
  DOWN_PAYMENT: 'Down Payment',
  PPM: 'PPM',
  PRODUCTION: 'Production',
  QC_FINAL_REPORT: 'QC Final Report',
  FINAL_PAYMENT: 'Final Payment',
  DELIVERY: 'Delivery',
  REVISION: 'Revision',
  COMPLETED: 'Completed',
}

export const DELIVERY_STATUS = {
  SCHEDULED: 'Scheduled',
  ON_DELIVERY: 'On Delivery',
  DELIVERED: 'Delivered',
  PENDING: 'Pending',
}

export const ITEMS_MAIN_STATUS = {
  REVIEW: 'Review',
  SAMPLING: 'Sampling',
  PRODUCTION: 'Production',
  DELIVERY: 'Delivery',
  ACTIVE: 'Active',
}

export const ORDER_SUMMARY_STATUS = {
  INQUIRY: 'Inquiry',
  ACTIVE_ORDER: 'Active Order',
  HOLD: 'Hold',
}

export const COOKIE_LOOKUP_KEY_LANG = 'i18next'
