import Image from 'next/image'
import { useRouter } from 'next/router'

const WifDashboardLogo: React.FC<{ simplified?: boolean }> = ({ simplified }) => {
  const router = useRouter()
  return (
    <div
      className='flex gap-2 items-baseline px-4 cursor-pointer'
      onClick={() => router.replace('/')}
    >
      <Image
        src={simplified ? '/icons/wif.svg' : '/icons/wifkain.svg'}
        alt='Wif Icon'
        width={86}
        height={36}
      />
    </div>
  )
}

export default WifDashboardLogo
