import clsx from 'clsx'
import Cookies from 'js-cookie'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'

import RightMenu from '@/components/common/RightMenu'
import HamburgerIcon from 'src/components/ui/Icon/HamburgerIcon'
import WifDashboardLogo from 'src/components/ui/Icon/WifDashboardLogo'
import WifModal from 'src/components/ui/WifModal'
import { COOKIE_LOOKUP_KEY_LANG } from 'src/constants'

/**
 *
 * @param {callback} onHamburgerClick to handle when clicking hamburger icon
 * @param {boolean?} simplified to toggle simplified mode of NavBar. Defaulted to false.
 * @param {string?} title to give title on the NavBar
 * @param {callback} onBackAction to be used with simplified=true for handling back button, put empty function if simplified=false
 *
 * Example: `<NavBar onBackAction={() => {}} simplified={false}>`
 * @returns
 */
const NavBar: React.FC<{
  onHamburgerClick: () => void
  simplified?: boolean
  title?: string
  onBackAction: () => void
}> = ({ onHamburgerClick, onBackAction, simplified = false, title = '' }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [isRedirectionModal, setIsRedirectionModal] = useState<boolean>(false)

  return (
    <div
      className={clsx(
        'flex items-center justify-between w-full h-[60px] px-4 ',
        'xs:h-[90px] xs:px-4'
      )}
    >
      {!simplified ? (
        <>
          <div className='flex items-center gap-2'>
            <HamburgerIcon onClick={onHamburgerClick} />
            <div className='xs:hidden'>
              <WifDashboardLogo />
            </div>
          </div>

          <RightMenu
            simplified={simplified}
            onCreateProduction={() => setIsRedirectionModal(true)}
          />
        </>
      ) : (
        <>
          <div className='hidden xs:flex xs:items-center xs:gap-2'>
            <HamburgerIcon onClick={onHamburgerClick} />
            <div className='hidden'>
              <WifDashboardLogo />
            </div>
          </div>

          <div className='flex items-center gap-4 xs:hidden' onClick={onBackAction}>
            <Image
              src='/icons/chevron-left.svg'
              alt='Back Icon'
              width={24}
              height={24}
              className='cursor-pointer -ml-[6px]'
            />
            <span className='text-sm font-bold'>{title}</span>
          </div>

          <RightMenu
            simplified={simplified}
            onCreateProduction={() => setIsRedirectionModal(true)}
          />
        </>
      )}

      {isRedirectionModal && (
        <WifModal
          id='redirection-create-web'
          actions
          bodyText={t('common:One_Platform_Redirection')!}
          onMainAction={() => router.push(`${process.env.NEXT_PUBLIC_WIFKAIN_DOMAIN}/wif-maas`)}
          onClose={() => setIsRedirectionModal(false)}
        />
      )}
    </div>
  )
}

export const LanguageToggles: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <div className='px-4 py-5 flex flex-col gap-5'>
      <button
        onClick={() => {
          Cookies.set(COOKIE_LOOKUP_KEY_LANG, 'en')
          router.replace(router.asPath, undefined, { locale: 'en' })
        }}
      >
        <div className='flex gap-2.5 font-medium'>
          <Image src='/icons/ic-en.png' alt={t('common:Word_English')} width={20} height={20} />
          <div>{t('common:Word_English')}</div>
        </div>
      </button>
      <button
        onClick={() => {
          Cookies.set(COOKIE_LOOKUP_KEY_LANG, 'id')
          router.replace(router.asPath, undefined, { locale: 'id' })
        }}
      >
        <div className='flex gap-2.5 font-medium'>
          <Image src='/icons/ic-id.png' alt={t('common:Word_Bahasa')} width={20} height={20} />
          <div>{t('common:Word_Bahasa')}</div>
        </div>
      </button>
    </div>
  )
}

export default NavBar
