import Cookies from 'js-cookie'
import { NextPage } from 'next'
import { appWithTranslation, useTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ReactElement, ReactNode, useEffect } from 'react'

import { COOKIE_LOOKUP_KEY_LANG } from 'src/constants'
import BaseLayout from 'src/layouts/BaseLayout'
import { AuthProvider } from 'src/providers/AuthProvider'
import '../styles/main.css'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout<T = any> = AppProps<T> & {
  Component: NextPageWithLayout
}

const DashboardApp = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  const { t } = useTranslation()
  useEffect(() => {
    const lang = Cookies.get(COOKIE_LOOKUP_KEY_LANG) || pageProps.lang
    router.replace(router.asPath, undefined, { locale: lang })
  }, [router.pathname])

  const simplifiedLayoutTitle = (): string => {
    if (router.pathname === '/orders/list') return t('orders:Order_List')
    if (router.pathname === '/items/[slug]/status') return t('orders:My_Orders')
    if (router.pathname === '/orders/timeline') return t('orders:Timeline')
    if (router.pathname === '/orders/[recordId]') return t('orders:Order_Detail')
    if (router.pathname === '/items/[slug]') return t('orders:Item_Detail')
    if (router.pathname === '/productions/list') return t('production:Production_Page_Title')

    return ''
  }

  const getLayout = Component.getLayout
    ? (page: ReactElement) => page
    : (page: ReactElement) => (
        <BaseLayout
          onBackAction={() => router.back()}
          simplified={!!simplifiedLayoutTitle()}
          simplifiedTitle={simplifiedLayoutTitle()}
        >
          {page}
        </BaseLayout>
      )

  return (
    <>
      <Head>
        <title>Wif Dashboard</title>
        <meta name='application-name' content='Wifkain App' />
        <meta name='description' content='Wif Dashboard by WifKain' />

        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content='Wifkain App' />

        <meta name='format-detection' content='telephone=no' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='msapplication-config' content='/icons/browserconfig.xml' />
        <meta name='msapplication-TileColor' content='#2B5797' />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name='theme-color' content='#E48E03' />

        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
        />
      </Head>

      <AuthProvider>{getLayout(<Component {...pageProps} />)}</AuthProvider>
    </>
  )
}

export default appWithTranslation(DashboardApp)
