import Image from 'next/image'

interface IconProps {
  active?: boolean
  iconSrc: string
  activeIconSrc?: string
  alt: string
  lazyLoad?: boolean
  width?: number
  height?: number
}

/**
 * Component that displays an SVG icon via Next Image
 * with the ability to switch between active / inactive states
 * LazyLoading set to true by default
 *
 * @param {IconProps} props
 * @param {boolean} props.active when set to `true`,
 * component will display the `activeIconSrc` instead of `iconSrc`
 * @param {string} props.iconSrc the url path of the svg icon to load
 * @param {string=} props.activeIconSrc the url path of the active svg icon to load
 * @param {string} alt text for the image alt tag
 * @param {boolean=} props.lazyLoad When set to true, delays the loading of the icon
 * until is near the fold. Defaults to true.
 */
const Icon = ({
  active = false,
  iconSrc,
  activeIconSrc,
  alt,
  lazyLoad = true,
  width = 30,
  height = 30,
}: IconProps) => {
  const src = activeIconSrc && active ? activeIconSrc : iconSrc
  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      priority={!lazyLoad}
      data-testid={`image-${alt}`}
    />
  )
}

export default Icon
