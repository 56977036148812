import axios, { HttpStatusCode } from 'axios'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

type AuthContextValue = {
  authToken?: string
  userData?: any
  setAuthToken: (token: string) => void
  removeTokens: () => void
  token?: string
  authLogout: () => Promise<void>
}

export const AuthContext = React.createContext({} as AuthContextValue)

function isTokenValid(token: string): boolean {
  const jwt: any = jwt_decode(token)
  const expSeconds = jwt.exp

  return Date.now() < expSeconds * 1000
}

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userData, setUserData] = useState<any>()
  const [authToken, setToken] = useState<string | undefined>(Cookies.get('wif-token'))
  const router = useRouter()

  /**
   * If fails, don't remove tokens as it might caused by HTTP errors instead of invalid token.
   */
  const getAndSetUser = async () => {
    try {
      const result = await axios.get('/api/maas/me')
      setUserData(result.data)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (
          err.response?.status === HttpStatusCode.Unauthorized ||
          err.response?.status === HttpStatusCode.NotFound
        ) {
          router.push('/login')
        } else {
          console.error(err)
        }
      }
    }
  }

  const removeTokens = () => {
    Cookies.remove('wif-token', {
      domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.wifkain.com',
    })
    setToken('')
  }

  useEffect(() => {
    const token = Cookies.get('wif-token')

    if (!token || !isTokenValid(token)) {
      removeTokens()
      if (
        router.pathname !== '/login' &&
        router.pathname !== '/register' &&
        router.pathname !== '/register/terms-and-condition'
      ) {
        router.replace(`/login`)
      }
    } else {
      getAndSetUser()
    }
  }, [authToken])

  const setAuthToken = (token: string) => {
    if (token) {
      const jwt: any = jwt_decode(token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      Cookies.set('wif-token', token, {
        domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.wifkain.com',
        secure: process.env.NODE_ENV !== 'development',
        expires: new Date(jwt.exp * 1000),
      })
      setToken(token)
    } else {
      delete axios.defaults.headers.common['Authorization']
    }
  }

  const authLogout = async () => {
    try {
      await axios.post('/api/maas/logout')
      setUserData(null)
      removeTokens()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const value = {
    setAuthToken,
    setUserData,
    removeTokens,
    userData,
    authToken,
    authLogout,
  } as AuthContextValue

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
