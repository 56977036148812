import clsx from 'clsx'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import MainContent from './MainContent'
import SideBar from './SideBar'

/**
 *
 * @param {boolean?} simplified To render layout with simplified NavBar
 * @param {string?} simplifiedTitle To render simplified title when `simplified=true`
 * @param {callback} onBackAction To handle back action when `simplified=true`. Left it as empty function if `simplified=false`
 *
 * Example: `<BaseLayout simplified={false} onBackAction={() => {}} >`
 * @returns
 */
const BaseLayout: React.FC<
  PropsWithChildren<{
    simplified?: boolean
    simplifiedTitle?: string
    onBackAction: () => void
  }>
> = ({ children, simplified = false, simplifiedTitle = '', onBackAction }) => {
  const router = useRouter()
  const isMobile = useMediaQuery({ maxWidth: 480 })

  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)

  useEffect(() => {
    !isMobile && setIsMenuExpanded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        'grid h-screen',
        isMenuExpanded ? 'xs:grid-cols-base' : 'xs:grid-cols-[70px_1fr]',
        'transition-all duration-250 ease-in-out'
      )}
    >
      <SideBar
        expanded={isMenuExpanded}
        onOverlayClick={() => setIsMenuExpanded(false)}
        onMenuClick={(url) => {
          isMobile && setIsMenuExpanded(false)
          router.push(url)
        }}
      />

      <MainContent
        onHamburgerClick={() => setIsMenuExpanded(!isMenuExpanded)}
        simplified={simplified}
        title={simplifiedTitle}
        onBackAction={onBackAction}
      >
        {children}
      </MainContent>
    </div>
  )
}

export default BaseLayout
