import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import Icon from './Icon'

type LinkVariantProps = {
  link: true
  href: string
}

type ButtonVariantProps = {
  link?: false
  submitType?: 'submit' | 'button'
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'dashed' | 'danger'

type WifButtonProps = {
  id: string
  type?: ButtonType
  text: string
  iconSrc?: string
  iconHeight?: number
  iconPosition?: 'left' | 'right'
  dense?: boolean
  border?: boolean
  disabled?: boolean
  textTransform?: 'normal-case' | 'uppercase' | 'lowercase' | 'capitalize'
  customStyle?: string
  isLoading?: boolean
  className?: string
} & (ButtonVariantProps | LinkVariantProps)

/**
 * Component that displays a button, configured according to the chosen style.
 *
 * @example
 * <WifButton
 *    type="secondary" // Configures the button styling to be white background, and bordered
 *    text="Add to cart" // The text label of the button
 *    iconSrc="/icons/cart.png" // The name of the file to be used as an icon. Make sure it is present in the static directory
 *    handleClick={() => console.log("Adding to cart!")} // A function to execute when the button is clicked
 * />
 *
 * @component
 */
const WifButton: React.FC<WifButtonProps> = (props: WifButtonProps) => {
  const {
    dense = true,
    type = 'primary',
    isLoading,
    border = true,
    textTransform = 'normal-case',
    iconSrc,
    iconPosition = 'left',
    iconHeight = 30,
    text,
    id,
    className,
    disabled = false,
  } = props
  const baseButtonClasses = [
    dense ? 'h-[48px]' : 'py-3',
    'px-5',
    'rounded-[10px]',
    'w-full',
    'text-sm',
    'text-center',
    'font-bold',
  ]

  let buttonClasses: string[]
  switch (type) {
    case 'primary':
      buttonClasses = [
        ...baseButtonClasses,
        disabled ? 'text-[#676767]' : 'text-white',
        disabled ? 'bg-[#F3F3F3]' : isLoading ? 'bg-[#B5E3FF]' : 'bg-primary',
      ]
      break
    case 'secondary':
      buttonClasses = [
        ...baseButtonClasses,
        border ? 'border-[1px]' : '',
        border ? 'border-primary' : '',
        'text-primary',
        disabled ? 'bg-[#F3F3F3]' : isLoading ? 'bg-[#B5E3FF]' : 'bg-white',
      ]
      break
    case 'tertiary':
      buttonClasses = [
        ...baseButtonClasses,
        disabled ? 'bg-[#F3F3F3]' : 'bg-wif-orange',
        'text-white',
        'shadow-[0px_4px_14px_0px_rgba(27,71,130,0.19)]',
      ]
      break
    case 'dashed':
      buttonClasses = [
        ...baseButtonClasses,
        disabled ? 'bg[#F3F3F3]' : 'bg-[#FDFAF5]',
        'text-wif-orange',
        'border border-dashed border-[#F3CE93]',
      ]
      break
    case 'danger':
      buttonClasses = [
        ...baseButtonClasses,
        border ? 'border-[1px]' : '',
        border ? (disabled ? 'border-[#E02B1D] border-opacity-40' : 'border-[#E02B1D]') : '',
        disabled ? 'text-[#E02B1D] text-opacity-40' : 'text-[#E02B1D]',
        isLoading ? 'bg-[#B5E3FF]' : 'bg-white',
      ]
      break
  }

  const innerSection = (
    <div className={clsx(textTransform, 'flex items-center justify-center gap-1')}>
      {isLoading && (
        <div className='relative mr-4 h-[20px] w-[20px] animate-spin'>
          <Image
            src='/icons/ic-button-loading.svg'
            fill
            alt='Wif Button Loading Icon'
            style={{ objectFit: 'fill' }}
          />
        </div>
      )}
      {iconSrc && iconPosition === 'left' && (
        <Icon iconSrc={iconSrc as string} height={iconHeight} width={iconHeight} alt={text} />
      )}
      {text}
      {iconSrc && iconPosition === 'right' && (
        <Icon iconSrc={iconSrc as string} height={iconHeight} width={iconHeight} alt={text} />
      )}
    </div>
  )

  return props.link ? (
    <Link href={props.href}>
      <div
        className={clsx(
          buttonClasses,
          'flex items-center justify-center',
          !disabled || 'cursor-not-allowed',
          className
        )}
      >
        {innerSection}
      </div>
    </Link>
  ) : (
    <button
      id={`wif-button-${id}`}
      data-testid={`wif-button-${id}`}
      onClick={props.handleClick}
      className={clsx(buttonClasses, 'disabled:cursor-not-allowed', className)}
      disabled={disabled || isLoading}
      type={props.submitType ?? 'submit'}
    >
      {innerSection}
    </button>
  )
}

export default WifButton
