export const getInitials = (name: string) => {
  let names = name.split(' '),
    initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export const snakeCase = (string: string) => {
  return string
    .toLowerCase()
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')
}

export function hoursToDaysHours(hours: number): [number, number] {
  const days = Math.floor(hours / 24)
  const remainingHours = hours % 24
  return [days, Number(remainingHours.toFixed(0))]
}
